Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.formData = "formData";
exports.dashboarApiMethodType = "GET";
exports.postApiMethod = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.selectTxt = "Select";
exports.getProfileEndPoint = "account_block/accounts/";
exports.getStatusEndPoint = "bx_block_dashboard/status_count/";
exports.getTeamDataEndPoint = "bx_block_dashboard/students/team_performance";
exports.recentUpdateTitle = "Recent Update";
exports.entryRequirementsTitle = "Heriot Watt New Entry Requirements:";
exports.scholarshipDetail = "Massive Guaranteed scholarships: GBP 1500, GBP 2500, GBP 3000 for all your students with a score of 60% and above, based on the courses.";
exports.revisedEnglishRequirement = "Revised English requirement: Reduced to 70% from 75%";
exports.postedOnDate = "Posted on: ";
exports.viewMoreText = "View more";
exports.pendingTasksTitle = "Pending tasks";
exports.tableHeaders = {
  appId: "App id",
  title: "Title",
  student: "Student",
  university: "University",
  dueDate: "Due date",
  daysLeft: "Days left"
};
exports.teamPerformanceTitle = "Team Performance";
exports.tableHeadersTeam = {
  teamMembers: "Team members",
  assignedStudents: "Assigned Students",
  activeApplications: "Active Applications",
  tuitionFeePaid: "Tuition Fee Paid",
  conversion: "Conversion"
};
exports.balanceTitle = "Your balance";
exports.balanceAmount = "$10 CAD";
exports.selectPlaceholder = "Select";
exports.studentCardTitle = "Students";
exports.addStudentButtonText = "Add New Student";
exports.startNewApplication = "Start New Application";
exports.agentName = "Neha Bhullar";
exports.agentTitle = "Client Relationship Officer";
exports.agentPhone = "+91 888 877 7881";
exports.offerHeaderText = "Offers";
exports.viewMoreText = "View more";
exports.noOffersText = "No offers available";
exports.eligibleCountriesText = "Eligible Countries";
exports.appFeeWaivedText = "App Fee Waived";
exports.promotionEndsOnText = "Promotion ends on";
exports.servicesTitle = "Other services";
exports.comingSoonText = "Coming soon";
exports.inTakesTxt = "Open Intakes";
exports.popularText = "(Popular populations)";
exports.studentTxt = "345";
exports.dotText = "...";
exports.offersHeaderText = "Offers";
exports.filterText = "Filter";
exports.filterAppliedText = "applied";
exports.appFeeWaivedText = "App Fee Waived";
exports.eligibleCountriesText = "Eligible Countries";
exports.filterText = "Filter";
exports.universityText = "University";
exports.expiryDateText = "Expiry date";
exports.resetFilterText = "Reset Filter";
exports.applyFilterText = "Apply Filter";
exports.universiyPrompt = "Choose University";
exports.universityTextValue = "McGill University";
exports.expiryPlaceholder = "Choose expiry date";
exports.totalApplicationsText = "Total Applications";
exports.paidApplicationText = "Paid Application";
exports.rejectedText = "Rejected";
exports.enrolledText = "Enrolled";
exports.getrecentUpdateEndPoint = "/bx_block_dashboard/latest_updates/";
exports.noData = "No data available";
exports.getOfferEndPoint = "/bx_block_dashboard/offers/";
exports.getOtherServiceEndPoint = "/bx_block_dashboard/other_services";
exports.recentUpdatesTxt = "Recent Updates";
exports.getSalesManagerEndPoint = "bx_block_dashboard/sales_manager_info";
exports.noAgentTxt = "No sales staff assigned yet";
exports.filterApiEndPoint = "/bx_block_dashboard/offer_filters";
exports.universityEndPoint = "/bx_block_dashboard/set_offer_filters";
exports.imgIcons = "https://img.icons8.com/?size=100&id=99268&format=png&color=000000";
exports.inTakesApiEndpoint = "/bx_block_dashboard/open_intakes";
exports.countryEndPoint = "/bx_block_dashboard/university_country";
exports.noIntake = "No intake available";
exports.noDataFound = "No data available";
exports.offeredBy = "Offered by";
exports.otherServiceTxt = "Other services";
exports.headingOneTxt = "Open GIC Account - Canada";
exports.headingTwoTxt = "Open Block Account (Gerrmany)";
exports.headingThreeTxt = "Airlines Ticket Booking (Air Saathi)";
exports.headingFourTxt = "Buy PTE Vouchers";
exports.descriptionOneTxt = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis cursus tellus placerat pretium. Morbi lectus orci, maximus a commodo non, consequat non risus.";
exports.descriptionTwoTxt = "Esctetur adipiscing elit. Aenean iaculis cursus tellus placerat pretium. Morbi lectus orci, maximus a commodo non, consequat non risus hguyt.";
exports.descriptionThreeTxt = "Esctetur adipiscing elit. Aenean iaculis cursus tellus placerat pretium. Morbi lectus orci, maximus a commodo non, consequat non risus hguyt.";
exports.descriptionFourTxt = "Jnean iaculis cursus tellus placerat pretium. Morbi lectus orci, maximus a commodo non, conseq.";
exports.openBtnTxt = "Open account";
exports.bookeAirLineTicket = "Book airline ticket";
exports.voucherTxt = "Buy voucher";
exports.studentModalTitle = "Select student"
exports.studentModalDescription = "Select student for which you would like to open an account.";
exports.openAccountBtn = "Open an account for selected student";
exports.lastStudent = "Student";
exports.addStudentTxt = "Add New Student";
exports.name = "Dinesh";
exports.selectStudent = "Select student";
exports.tableHeaderOne = "SERVICE";
exports.tableHeaderTwo = "STUDENT";
exports.tableHeaderThree = "REQUEST ID";
exports.tableHeaderFour = "STATUS";
exports.tableHeaderFive = "DATE";
exports.tableHeading = "Services Requests";
exports.serviceRequestPlaceHolder = "Sort by service";
exports.showingResult = "Showing Results";
exports.requestText="Request successfully created!";
exports.requestIdText="Your Request id is";
exports.idText="98Y4554588.";
exports.getTableEndPoint="/bx_block_dashboard/other_services/student_services";
exports.getStudentEndPoint = "/bx_block_dashboard/other_services/student_drop_down";
exports.studentTxt="Student ID: ";
exports.passportTxt="Passport No: ";
exports.openAccountEndpoint = "bx_block_dashboard/other_services/add_student_service";
exports.requestId="Your request id is ";
exports.okTxt="Ok";
exports.sortByEndpoint = "/bx_block_dashboard/other_services/sort_service";
exports.readmoreTxt = "Read More";
exports.promoCodeText = "Promo Code";
exports.discountValueText = "Discount Value";
// Customizable Area End